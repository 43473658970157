footer {
    z-index:1;
}

.footer-icon-links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    padding-bottom:1em;
    padding-top:6em;

    @include respond-below(xs) {
        float:left !important;
        padding-top:0.5em !important;
        padding-left:0 !important;
      }


    a {
        height:36px;
        width:36px;        
        display: flex;
        text-align: center;
        line-height:36px;

        color:#8d6e63;
        transition: all .2s ease-in-out;

        &:hover {
            color:$primary-color;
            transform: scale(1.5);
        }
    }

    i {
        font-size:22px;
        margin:auto;
    }
}

.footer-icon {
    height:36px;
    width:36px;
    background-size:contain;
}
.footer-icon-home {
    background-image:url('../img/metatope_logo.png');
}
.footer-icon-discord {
    background-image:url('../img/footer_discord.png');
}
.footer-icon-twitter {
    background-image:url('../img/footer_twitter.png');
}
.footer-icon-instagram {
    background-image:url('../img/footer_instagram.png');
}
.footer-icon-tiktok {
    background-image:url('../img/footer_tiktok.png');
}
.footer-icon-youtube {
    background-image:url('../img/footer_youtube.png');
}

.footer-icon-item {
    margin:0 5px;
}

.footer-heart {
    color:#E45658;
}

a.text-muted:hover {
    color:$primary-color;
}

.footer-copyright {
    display: block;
    text-align: center;
    font-size:0.9em; 
    margin-bottom:1em;
}

footer {
    flex:0;
    margin-top:auto;
}

.footer-content {
    display:flex;
    flex-direction: column;
}