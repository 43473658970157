/* orbitron-regular - latin */
@font-face {
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 400;
    src: url('../font/orbitron-v24-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../font/orbitron-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../font/orbitron-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../font/orbitron-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../font/orbitron-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../font/orbitron-v24-latin-regular.svg#Orbitron') format('svg'); /* Legacy iOS */
  }
  /* orbitron-500 - latin */
  @font-face {
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 500;
    src: url('../font/orbitron-v24-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../font/orbitron-v24-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../font/orbitron-v24-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../font/orbitron-v24-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../font/orbitron-v24-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../font/orbitron-v24-latin-500.svg#Orbitron') format('svg'); /* Legacy iOS */
  }
  /* orbitron-700 - latin */
  @font-face {
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 700;
    src: url('../font/orbitron-v24-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../font/orbitron-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../font/orbitron-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../font/orbitron-v24-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../font/orbitron-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../font/orbitron-v24-latin-700.svg#Orbitron') format('svg'); /* Legacy iOS */
  }
  /* orbitron-600 - latin */
  @font-face {
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 600;
    src: url('../font/orbitron-v24-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../font/orbitron-v24-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../font/orbitron-v24-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../font/orbitron-v24-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../font/orbitron-v24-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../font/orbitron-v24-latin-600.svg#Orbitron') format('svg'); /* Legacy iOS */
  }
  /* orbitron-800 - latin */
  @font-face {
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 800;
    src: url('../font/orbitron-v24-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../font/orbitron-v24-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../font/orbitron-v24-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../font/orbitron-v24-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../font/orbitron-v24-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../font/orbitron-v24-latin-800.svg#Orbitron') format('svg'); /* Legacy iOS */
  }
  /* orbitron-900 - latin */
  @font-face {
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 900;
    src: url('../font/orbitron-v24-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../font/orbitron-v24-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../font/orbitron-v24-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../font/orbitron-v24-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../font/orbitron-v24-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../font/orbitron-v24-latin-900.svg#Orbitron') format('svg'); /* Legacy iOS */
  }