/* exo-2-regular - latin */
@font-face {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/exo-2-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/exo-2-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/exo-2-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/exo-2-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/exo-2-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/exo-2-v20-latin-regular.svg#Exo2') format('svg'); /* Legacy iOS */
  }
  /* exo-2-800 - latin */
  @font-face {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/exo-2-v20-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/exo-2-v20-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/exo-2-v20-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/exo-2-v20-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../fonts/exo-2-v20-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/exo-2-v20-latin-800.svg#Exo2') format('svg'); /* Legacy iOS */
  }