// Base
@import "mixins";
@import "./materialize";
@import "./background";
@import "./progressbar";

// Fonts
@import "../fonts/orbitron/css/orbitron.css";
@import "../fonts/exo2/css/exo2.css";
@import "../fonts/custom-icons/css/iconcustom.css";
@import "../fonts/font-awesome/css/fontawesome-all.css";

// Libraries
@import '../../../node_modules/react-toastify/dist/ReactToastify.css';

// Custom components
@import "./footer";

body {
  overflow-x:hidden;
}

html {
  background-color:#1a0633;
  font-family: Helvetica Neue, sans-serif;
}

.main-container {
  min-height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
* {
  margin: 0;
  padding: 0;
}

.logo {
  margin: 0 auto 0 auto;

  .logo-image {
    background-image: url('../img/metatope_logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width:320px;
    height:100px;
    display: block;
    margin: auto;
  }

  .logo-text {
    background-image: url('../img/metatope_word.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width:320px;
    height:57px;
    display: block;
    margin: 1em auto 0 auto;
  }
  
  .logo-header-text {
    font-family: 'Orbitron';
    font-size:1.6em;
    margin:0em auto 0 auto;
    text-align: center;
    color:#a29ab3;
    text-transform:uppercase;
    font-weight:500;
  }
}

.btn-large {
  margin-top:2em;
}
.btn-large i {
  font-size:1rem;
  padding-left:0.5em;
  padding-right:0.5em;
}

input[type=text].form-control,
input[type=email].form-control,
input[type=tel].form-control {
  font-size:16px;
  padding-left:5px;
  padding-right:5px;
  background-color: rgba(255,255,255,0.15);
  color:#fff;
  text-align:center;

  &:focus {
    border-bottom: 1px solid $primary-color !important;
    box-shadow: 0 1px 0 0 $primary-color !important;
  }
}

textarea {
  font-size:16px;
  padding:5px;
  background-color: rgba(255,255,255,0.15);
  color:#fff;
  text-align:left;
  width:75%;
  min-width:75%;
  max-width: 100%;
  height:8rem;

  &:focus {
    border-bottom: 1px solid #ffa200 !important;
    box-shadow: 0 1px 0 0 #ffa200 !important;
  }
}

.header {
  font-family: 'Orbitron';
  font-weight:400;
  color: #ffeec4;
  font-size:2em;
  line-height:1.3em;
  text-transform:uppercase;
  -webkit-text-stroke-color:#1895d8;
  -webkit-text-stroke-width:1px;
}

.header-small {
  font-family: 'Orbitron';
  font-weight:400;
  color: #ffeec4;
  font-size:1.4em;
  line-height:1.6em;
}

.sub-header {
  font-weight:400;
  color:$primary-color;
  font-size:1em;
}

p,
div.p {
  margin-bottom:1em;
  color:#ddd;
  font-weight:500;
  position: relative;
}

strong {
  color:#ddd;
}

.loader {
  display: block;
  margin: 2em auto 0 auto;

  .circle {
    border-width:6px;
    border-color:$primary-color;
  }
}

.balance {
  font-size:1.5em;
}

.tier-buttons {
  button {
    margin-left:0.25em;
    margin-right:0.25em;
    height:4em;
    line-height:1.5em;
    padding-top:1em;
    padding-bottom:1em;
    width:12em;

    span {
      display: block;
    }

    .tier-title {
      font-size:1.5em;
      font-weight:300;
      text-align: center;
    }

    .tier-subtitle {
      display: block;
      font-family:Helvetica Neue, sans-serif;
      font-weight:200;
      text-transform:none;
      font-size:0.8em;
      max-width: 7em;
      line-height: 1.3em;
      margin-top: 0.8em;   
      text-align: center;
      margin-left:auto;
      margin-right:auto;
    }
  }
}

.main-container .container {
  padding-top:4em;
  max-width:800px;
  z-index:2;
}

.form-group {
  margin-top:3em;
}

strong {
  font-weight:800;
}

.btn-small {
  font-weight:300;
  line-height:3em;
  height:auto;
  background-color: color("grey", "base");
}

.btn-large:disabled {
    background:#0a1c3f !important;
    opacity: 0.8;
    border: 1px solid #8d6e63;
}

.helper-text.error-text {
  display: block;
  color: $error-color;
  font-weight:500;
  margin-bottom:-1em;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

hr {
  margin:5em auto 5em auto;
  border-width:1px;
  border-color:#880781;
}

.btn-tier {
  min-height:8em;
}

.btn {
  font-family: 'Orbitron';
  background:#0a1c3f;
  border:1px solid #ffa200;

  &:hover {
    background: #ffa200;
  }

  &:focus {
    background: #1a0633;
  }
}

.text-button {
  cursor: pointer;
  display: inline-block;
  font-size:0.8em;
  background:none;
  border:none;
  margin-left:1em;
  margin-right:1em;
  color:darken($primary-color, 5%);
  transition: all .2s ease-in-out;

  &.x2 {
    font-size:1em;
    line-height:2em;

    i {
      margin-right:5px;
    }
  }

  &.text-button-inline {
    font-size:1em;
    margin:0;
  }

  &:focus {
    background:none;
  }
  &:hover {
    color:#0BC7DE;
    transform: scale(1.2);
  }
}

// TODO: use tailwind
.d-inline-block { display:inline-block; }
.mt-1 { margin-top: 1em; }
.mt-2 { margin-top: 2em; }
.mt-3 { margin-top: 3em; }
.mt-4 { margin-top: 4em; }
.mt-5 { margin-top: 5em; }
.mt-6 { margin-top: 6em; }
.mb-1 { margin-bottom: 1em; }
.mb-2 { margin-bottom: 2em; }
.mb-3 { margin-bottom: 3em; }
.mb-4 { margin-bottom: 4em; }
.mb-5 { margin-bottom: 5em; }
.mr-0-5 { margin-right: 0.5em; }
.mr-1 { margin-right: 1em; }
.mr-2 { margin-right: 2em; }
.mr-3 { margin-right: 3em; }
.mr-4 { margin-right: 4em; }
.mr-5 { margin-right: 5em; }
.mr-6 { margin-right: 6em; }
.ml-0-5 { margin-left: 0.5em; }
.ml-1 { margin-left: 1em; }
.ml-2 { margin-left: 2em; }
.ml-3 { margin-left: 3em; }
.ml-4 { margin-left: 4em; }
.ml-5 { margin-left: 5em; }
.ml-6 { margin-left: 6em; }

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.text-btn {
  background:none;
  border:none;
  border-bottom:1px solid #ffa200;
  color:#fff;
  cursor:pointer;

  &:hover {
    color:#ffa200;
  }

  &:focus {
    background:none;
  }
}

.tier-table {
  color:#fff;
  border-top:1px solid #55515e;
  margin-top:4em;

  td {
    padding:30px 15px;
    height:9em;
  }

  tr {
    border-bottom:1px solid #55515e;
  }

  tr:hover {
    background-color:rgb(255, 255, 255, 0.1);
  }

  .title {
    font-family: 'Orbitron';
    font-size:1.3em;
    display: block;
    text-transform: uppercase;
  }

  .description {
    color:#a29ab3;
  }

  .btn-table-tier {
    margin-top:0.6em;
    height:42px;
    line-height:42px;
    width:200px;
    font-size:1em;
  }

  .count-label {
    color:#a29ab3;
  }

  .closed {
    color:#a29ab3;
  }
}

.disclaimer-note {
  color:#898298;
  font-size:0.8em;
  margin-top:4em;
}

/* fix scroll bug in react-step-wizard */
.rsw_2f {
  display: none;
}
.rsw_3G {
  display: block;
}

.tier-title {
  font-family: 'Orbitron';
  font-size:2em;
  font-weight:bold;
  display: block;
  text-transform: uppercase;
}

.muted {
  color:#999;
}

.highlight-text {
  font-size:1.2rem;
  font-family:'Orbitron';
  font-weight:bold;
}

.web3modal-provider-name {
  font-size:1.1em !important;
}

.web3modal-provider-description {
  font-size:0.9em !important;
}

.text-muted-small {
  font-size:0.9em;
  color:#999;
}

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;

.alert {
  display:inline-block;
  padding:1em 2em;
  border-radius:1em;
}

.alert-warning {
  color: $warning-color;
  background-color: rgba($warning-color, 0.2);
  border-color: rgba($warning-color, 0.1);
  line-height:2em;

  i {
    font-size:2em;
    margin-right:0.2em;
  }

  .text-button {
    font-weight:bold;
    color:rgba($warning-color, 1.5);
  }
}

.x2 {
  i { font-size:2em;padding:0; }
  display:flex;
  justify-content: center;
  margin-left:auto;
  margin-right:auto;
}

.landing-icon {
  width: 140px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  margin: auto auto 2em auto;
  background-size:contain;
  background-position: center center;
  background-repeat: no-repeat;
  
  &.wallet {
    background-image: url('../img/landing_box_wallet.png');
  }

  &.social {
    background-image: url('../img/landing_box_twitter.png');
  }

  &.questions {
    background-image: url('../img/landing_box_clipboard.png');
  }
}

.radio-button {
  justify-content: center;
  align-items: center;
  border: 1px solid #55515e;
  background:rgb(#000, 0.3);
  cursor:pointer;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  position:relative;
  padding:2em 2em;
  color:#ddd;

  &:hover {
    background-color:#0a1c3f;
  }

  &.selected,
  &.selected:hover {
    border-color:$primary-color;
    background-color:#0a1c3f;
  }

  i.fa-check {
    display:none;
    color:#fff;
    background-color: darken($primary-color, 5%);
    border:1px solid $primary-color;
    border-radius:50%;
    line-height: 16px;
    font-size:16px;
    padding:6px;
    position: absolute;
    top:-13px;
    left:calc(50% - 13px);    
  }

  &.selected {
    i.fa-check {
      display: block;
    }
  }
}

.yes-no-radio-button {
  width:5em;
  padding:1em 1em;

  &.selected {
    i.fa-check {
      top:-16px;
      font-size:12px;
      line-height:12px;
    }
  }
}

.category-radio-button {
  height: 8em;
  padding:2em 1em;
  border-radius: 5px;
}

.category-title {
  color:#ddd;
  font-weight:bold;
  font-size:1.15em;
}

.category-description {
  color:#a29ab3;
  font-size:0.8em;
}

.align-right {
  text-align: right;
}

.top-right {
  position:absolute;
  top:8px;
  right:-12px;
}

.status-pill {
  padding:1em 2em;
  display:inline-block;
  margin:0 auto;
  line-height: 1em;
  border-radius:50px;
  border-width:2px;
  border-style: solid;
  font-weight:600;

  &.status-pill-pending {
    border-color:#ffc107 !important;
    color:#ffc107;
  }

  &.status-pill-approved {
    border-color:#b2ff59 !important;
    color:#b2ff59;
  }

  &.status-pill-rejected {
    border-color:#ff5252 !important;
    color:#ff5252;
  }
}

.checkbar-container {
  overflow:hidden;
  display:block;
  padding:1em 28px;
  background: rgba(0,0,0,0.2);
  border-bottom:1px solid #333;
  display:flex;
  justify-content: space-between;
}

.checkbar-affiliate-code {
  color:#55b776;
  margin-top:1em;
}

.check-status-button {
  font-size:0.8em;
  height:3em;
  line-height:3em;
  padding-left:1em;
  padding-right:1em;
  margin-top:0;
  
  &.back-button {
    border:none;
    background:none;

    &:hover {
      color:#ffa200;
    }
  }
}

.check-warning {
  font-weight: 400;
  color: #ddd;
  font-size: 1em;
  line-height: 1.3em;

  strong { font-weight:500; }

  .muted { font-weight:400; }
  
  i {
    font-size:1.7em;
    padding-bottom:13px;
  }
}

.social-profile {
  display:block;

  span {
    display: block;
    margin: auto;
  }

  .social-picture {
    display: inline-block;
    height:86px;
    width:86px;

    &.social-picture-google img,
    &.social-picture-twitter img {
      border-radius:50%;
    }

    &.social-picture-twitter {
      width:48px;
      height:48px;

      img {
        max-width:48px;
      }
    }

    img {
      max-height: 80px;
    }
  }

  .social-name {
    color:#ddd;
    font-size:1.3em;
  }

  .social-nickname {
    font-size:0.9em;
  }

  .social-verified {
    margin-top:1em;
    font-size:0.8em;
    color:#55b776;
  }
}

.facebook-profile-pic {
  width:80px;
  height:80px;
  background:url('../img/social/facebook.png') no-repeat center center;
  background-size: 70px;
  margin:auto;
}

.social-button {
  height: 100px;
  line-height:2em;
  width:100%;
  padding:0;

  &:focus {
    border:1px solid #0BC7DE;
    background:#0a1c3f;

    i.fa-check {
      display: block;
    }
  }

  i {
    display: block;
    font-size:28px;
  }

  span {
    font-size:12px;
    text-transform: none;
    font-weight: normal;
    white-space: nowrap;
  }
}

.confirm-title {
  color: darken(#ffdb00, 10%);;
  font-size:1em;
}

.confirm-value {
  color: #ddd;
  font-size:1.1em;
}

.confirm-divider {
  color:#55515e;
  display: inline-block;
  margin:0 2em;
}

//old page exiting, hides it underneath (bottom) 
.pageSlider-exit .section {
  z-index: 10;
}
//new page entering, show it on top
.pageSlider-enter .section {
  z-index: 11;
}
//LEFT transition transform 100% to the right
.pageSlider-enter.slide-left .section {
  transform: translateX(100%);
  opacity: 0;
}
//RIGHT transition transform -100% to the left
.pageSlider-enter.slide-right .section {
  transform: translateX(-100%);
  opacity: 0;
}
//old exit left direction
.pageSlider-exit.slide-left .section {
  transform: translateX(-100%);
  opacity: 1;
  display: none;
}
//old exit right direction 
.pageSlider-exit.slide-right .section {
  transform: translateX(100%);
  opacity: 1;
  display: none;
}
//Enter is active (Page has finished transitioning) make it settle down 
.pageSlider-enter.pageSlider-enter-active .section {
  transform: translateX(0);
  opacity: 1;
  //Apply all transitions 
  transition: all 800ms ease-in-out;
}





//old page exiting, hides it underneath (bottom) 
.fade-exit > div {
  z-index: 10;
}
//new page entering, show it on top
.fade-enter > div {
  z-index: 11;
}
//LEFT transition transform 100% to the right
.fade-enter > div {
  opacity: 0;
}

//old exit left direction
.fade-exit > div {
  opacity: 1;
  display: none;
}

//Enter is active (Page has finished transitioning) make it settle down 
.fade-enter.fade-enter-active > div {
  opacity: 1;
  //Apply all transitions 
  transition: all 800ms ease-in-out;
}

.App {
  z-index:22;
}

.w85 {
  width:85%;
}

.icon-15 {
  font-size:1.5em;
  padding:0 5px;
}

.nft-radio-button {
  padding:0.5em;
}

.nft-title {
  font-size:0.9em;
}

.nft-chain {
  font-size:0.8em;
}

.nft-small-text {
  font-size:0.8em;
}

.nft-image {
  width:120px;
  height:120px;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  margin:0 auto;
  background-size:100%;

  &.bayc {
    background-image:url('../img/nft/bayc.png');
    background-size:75%;
  }

  &.mayc {
    background-image:url('../img/nft/mayc.png');
    background-size:60%;
  }

  &.cryptopunks {
    background-image:url('../img/nft/cryptopunks.png');
  }
  
  &.clonex {
    background-image:url('../img/nft/clonex.png');
    background-size: 100%;
    background-position-y:80%;
  }
  
  &.doodles {
    background-image:url('../img/nft/doodles.png');
  }
  
  &.azuki {
    background-image:url('../img/nft/azuki.png');
  }
  
  &.degods {
    background-image:url('../img/nft/degods.png');
    background-size:60%;
  }
  
  &.yoots {
    background-image:url('../img/nft/yoots.png');
    background-size:80%;
  }
  
  &.spacebudz {
    background-image:url('../img/nft/spacebudz.svg');
    background-size:70%;
  }
  &.clay-nation {
    background-image:url('../img/nft/clay-nation.png');
  }
  
  &.ape-society {
    background-image:url('../img/nft/ape-society.png');
    background-size:70%;
  }

  &.nft-image-small {
    height:70px;
    width:70px;
  }
}

.nft-image-other {
  padding-top:3em;
  
  i {
    font-size:3em;
  }
}

.nft-small-wrapper {
  width:120px;
  display:inline-block;
  border:1px dotted #55515e;
  margin:5px;
  padding:0 0 15px 0;
  background:rgba(#fff, 0.075);
}

.checkbox-wrapper {
  color:#bbb;
  cursor:pointer;
  display:inline-flex;
}

.checkbox-icon {
  min-width:30px;
  max-width:30px;
  min-height:30px;
  max-height:30px;
  line-height:30px;
  display: inline-block;
  border:1px solid #777;
  color:#0BC7DE;
  

  i {
    visibility: hidden;
  }

  &.checked {
    border: 1px solid #0BC7DE;
    background:#0a1c3f;

    i {
      visibility: visible;
    }
  }
}

.checkbox-text {
  padding-top:6px;
  padding-left:10px;
  text-align:left;
}

.divider-small {
  margin-left:auto;
  margin-right:auto;
  display: block;
  border:none;
  background:url('../img/divider_small.png') center center no-repeat;
  background-size:contain;
  width:192px;
  height:53px;
}

.divider-large {
  margin-left:auto;
  margin-right:auto;
  display: block;
  border:none;
  background:url('../img/divider_large.png') center center no-repeat;
  background-size:contain;
  max-width:768px;
  height:54px;
}

.logo-triangle {
  background:url('../img/logo_triangle.png') no-repeat center center;
  width:161px;
  height:155px;
  background-size:contain;
  display:block;
  margin-left:auto;
  margin-right:auto;
}

.custom-button {
  display: inline-flex;
  height:50px;
  background: none;
  border:none;
  color:#ffdb00;
  cursor:pointer;

  &:focus {
    background:none;
  }

  > span {
    display: inline-block;
    height:50px;
    background-size:25px;
    background-image:url('../img/button_bg.png');    
  }

  .custom-button-left {
    width:25px;
    background-repeat:no-repeat;
    background-position: 0 0;
  }
  .custom-button-right {
    width:25px;
    background-repeat:no-repeat;
    background-position: 0 -50px;
  }  

  .custom-button-middle {
    background-repeat: repeat-x;
    background-position: 0 -100px;
    font-family:'Exo 2';
    font-weight:800;
    line-height:50px;
    text-transform:uppercase;
    padding-left:26px;
    padding-right:26px;
  }

  &:hover,
  &.custom-button-loading {
    .custom-button-left {
      background-position:0 -150px;
    }
    .custom-button-right {
      background-position:0 -200px;
    }
    .custom-button-middle {
      background-position:0 -250px;
    }        
  }

  &.custom-button-loading {
    .loader {
      height:24px !important;
      width:24px !important;
      margin:12px 6px;

      .circle {
        border-width:3px;
      }
    }
  }

  &.custom-button-small {
    height:40px;

    > span {
      background-size:20px;
      height:40px;
    }

    .custom-button-left {
      width:20px;
    }

    .custom-button-right {
      width:20px;
      background-position: 0 -40px;
    }

    .custom-button-middle {
      background-position: 0 -80px;
      line-height:40px;
      padding-left:8px;
      padding-right:8px;
    }

    &:hover {
      .custom-button-left {
        background-position:0 -120px;
      }
      .custom-button-right {
        background-position:0 -160px;
      }
      .custom-button-middle {
        background-position:0 -200px;
      }              
    }
  }
}

.submit-success {
  color:#A3ECD3;
  -webkit-text-stroke-color: #55b776;
  -webkit-text-stroke-width: 1px;
  font-size:1.5em;
  font-weight:400;

  i {
    margin-right:0.3em;
  }
}

.finished-check-list {
  width:auto;
  display: inline-block;
  margin:auto;

  td {
    color:#ddd;
    width:1%;
    white-space: nowrap;
  }

  .check-wrapper {
    min-width:30px;
    max-width:30px;
    min-height:30px;
    max-height:30px;
    line-height:30px;
    display: inline-block;
    border:2px solid #ffeec4;
    color:#ffeec4;
    text-align: center;
    background:#0a1c3f;

    /*
    &.check-success {
      border-color:#55b776;
      color:#55b776;
    }

    &.check-warning {
      border-color:$warning-color;
      color:$warning-color;
    }
    */

    i {
      line-height: 30px;
      font-size:18px;
    }
  }
}

.badge-small {
  font-size:0.75em;
  border:1px solid;
  padding:2px 5px;
  border-radius:5px;

  &.badge-success {
    color: #55b776;
    border-color:#55b776;
    background:rgb(0, 68, 0);
  }

  &.badge-warning {
    color: $warning-color;
    border-color: $warning-color;
    background:#493108;
  }
}

.twitter-share-button {
  display:inline-block;
  box-sizing: border-box;
  padding: 8px 18px 8px 18px;
  background-color: #1d9bf0;
  color: #fff;
  border-radius: 9999px;
  font-weight: 500;
  cursor: pointer;
  font-size:18px;

  &:hover {
    background-color: #0c7abf;
  }
}

.landing-img-large {
  width:130%;
  margin:auto;
  display: block;
  margin-left:-15%;

  @media (max-width: 992px) {
    width:100%;
    margin-left:auto;
  }
}

.landing-img {
  width:80%;
  margin:auto;
  display: block;
}