.progressbar-wrapper {
    overflow:hidden;
    display:block;
    padding:3em 1em 3em 1em;
    background: rgba(0,0,0,0.2);
    border-bottom:1px solid #333;
}

.progressbar li {
    list-style-type: none;
    width: 130px;
    // float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    color: #ddd;
}

.progressbar li:before {
    width: 36px;
    height: 36px;
    content: "";
    line-height: 36px;
    border: 2px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 3px auto;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background-color: #1D0537;
}
.progressbar li:after {
     width: 100%;
     height: 2px;
     content: '';
     position: absolute;
     background-color: #ddd;
     top: 17px;
     left: -50%;
     z-index: 0;
}
.progressbar li:first-child:after {
     content: none;
}

.progressbar li.complete {
    color: #55b776;
    font-weight: bold;
    cursor:pointer;
}
.progressbar li.complete:before {
    border-color: #55b776;
    background: green;
 }
.progressbar li.complete + li:after {
    background-color: #55b776;
}

.progressbar li.complete:before {
    // background: #55b776  url(user.svg) no-repeat center center;
    background-size: 60%;
}
.progressbar li::before {
    // background: #fff url(user.svg) no-repeat center center;
    background-size: 60%;
}

.progressbar {
    display:flex;
    justify-content: center;
}

.progressbar li:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size:1.3em;
}

.progressbar li.active:before {
    content: "\f111";
    line-height:32px;
}

.progressbar li.complete:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size:1.3em;
    content: "\f00c";
    color: #A3ECD3;
}