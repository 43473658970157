@font-face {
  font-family: 'iconcustom';
  src: url('../font/iconcustom.eot?49663840');
  src: url('../font/iconcustom.eot?49663840#iefix') format('embedded-opentype'),
       url('../font/iconcustom.woff2?49663840') format('woff2'),
       url('../font/iconcustom.woff?49663840') format('woff'),
       url('../font/iconcustom.ttf?49663840') format('truetype'),
       url('../font/iconcustom.svg?49663840#iconcustom') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'iconcustom';
    src: url('../font/iconcustom.svg?49663840#iconcustom') format('svg');
  }
}
*/
[class^="iconcustom-"]:before, [class*=" iconcustom-"]:before {
  font-family: "iconcustom";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.iconcustom-house:before { content: '\e800'; } /* '' */
.iconcustom-discord:before { content: '\e801'; } /* '' */
.iconcustom-tiktok:before { content: '\e802'; } /* '' */
.iconcustom-check:before { content: '\e803'; } /* '' */
.iconcustom-close:before { content: '\e804'; } /* '' */
.iconcustom-warning:before { content: '\e806'; } /* '' */
.iconcustom-external-link:before { content: '\f08e'; } /* '' */
.iconcustom-twitter:before { content: '\f099'; } /* '' */
.iconcustom-github:before { content: '\f113'; } /* '' */
.iconcustom-youtube:before { content: '\f16a'; } /* '' */
.iconcustom-google:before { content: '\f1a0'; } /* '' */
.iconcustom-twitch:before { content: '\f1e8'; } /* '' */
.iconcustom-facebook:before { content: '\f308'; } /* '' */
.iconcustom-linkedin:before { content: '\f30c'; } /* '' */
.iconcustom-instagram:before { content: '\f32d'; } /* '' */
